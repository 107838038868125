import { Component } from '@angular/core';

@Component({
  selector: 'app-erp-receipt-edit',
  templateUrl: './erp-receipt-edit.component.html',
  styleUrl: './erp-receipt-edit.component.css'
})
export class ErpReceiptEditComponent {

}
