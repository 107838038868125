import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { UiModule } from '../ui/ui.module';
import { ProcessEditorComponent } from './process-editor/process-editor.component';
import { ProcessViewComponent } from './process-view/process-view.component';
import { ProcessCategoryAndStatusManagementComponent } from './process-category-and-status-management/process-category-and-status-management.component';
import { ProcessProjectListComponent } from './process-project-list/process-project-list.component';
import { ProcessProjectTypeManagementComponent } from './process-project-type-management/process-project-type-management.component';
import { ProcessSearchComponent } from './process-search/process-search.component';
import { ProcessStatisticWorktimeComponent } from './process-statistic-worktime/process-statistic-worktime.component';
import { ProcessTeamManagementComponent } from './process-team-management/process-team-management.component';
import { ProcessWorkflowManagementComponent } from './process-workflow-management/process-workflow-management.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ProcessComponent } from './process/process.component';
import { PipesModule } from '../pipes/pipes.module';
import { MyDefaultTooltipOptions } from '../tooltip-options';
import { TooltipModule, TooltipOptions } from '@make-lynxwork/ng2-tooltip-directive';
import { ModalModule } from '../_modal';
import { ProcessManagementProjectComponent } from './process-management-project/process-management-project.component';
import { ProcessManagementBillingCategoryComponent } from './process-management-billing-category/process-management-billing-category.component';
import { ProcessManuallyAssignComponent } from './process-manually-assign/process-manually-assign.component';
import { ProcessFinishWorkdocumentComponent } from './process-finish-workdocument/process-finish-workdocument.component';
import { ProcessMergeComponent } from './process-merge/process-merge.component';
import { ProcessSearchWorkdocumentComponent } from './process-search-workdocument/process-search-workdocument.component';
import { AngularSplitModule } from 'angular-split';
import { DragDropModule } from '@angular/cdk/drag-drop';
@NgModule({
  declarations: [
    ProcessComponent,
    ProcessEditorComponent,
    ProcessViewComponent,
    ProcessCategoryAndStatusManagementComponent,
    ProcessProjectListComponent,
    ProcessProjectTypeManagementComponent,
    ProcessSearchComponent,
    ProcessStatisticWorktimeComponent,
    ProcessTeamManagementComponent,
    ProcessWorkflowManagementComponent,
    ProcessManagementProjectComponent,
    ProcessManagementBillingCategoryComponent,
    ProcessManuallyAssignComponent,
    ProcessFinishWorkdocumentComponent,
    ProcessMergeComponent,
    ProcessSearchWorkdocumentComponent,

  ],
  imports: [
    CommonModule, HttpClientModule, FormsModule, UiModule, EditorModule,
    PipesModule, ModalModule,
    TooltipModule.forRoot(MyDefaultTooltipOptions as TooltipOptions),
    AngularSplitModule,
    ContextMenuModule,
    DragDropModule
  ],
  exports: [
  ]
})
export class ProcessModule { }
