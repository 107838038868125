import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { SettingsService } from './services/settings.service';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { AngularSplitModule } from 'angular-split';
import { ProcessModule } from './process/process.module';
import { UiModule } from './ui/ui.module';
import { CoreModule } from './core/core.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { InventoryModule } from './inventory/inventory.module';
import { ErpModule } from './erp/erp.module';
import { TutorialService } from './services/tutorial.service';
import { TimeTrackingModule } from './time_tracking/time-tracking.module';
import { HardwareModule } from './hardware/hardware.module';
import { CrmModule } from './crm/crm.module';
import { WorkflowModule } from './workflow/workflow.module';
import { ControllingModule } from './controlling/controlling.module';
import {AdminModule} from './admin/admin.module';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {LOCALE_ID} from '@angular/core';
import {CustomerPortalModule} from "./customer-portal/customer-portal.module";
import {WhistleblowerModule} from "./whistleblower/whistleblower.module";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
registerLocaleData(localeDe);

const settingsInit = (settings: SettingsService) => {
    return() => {
        return settings.initSettings();
    };
};

@NgModule({
  declarations: [
    AppComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        EditorModule,
        ContextMenuModule,
        AngularSplitModule,
        CoreModule,
        UiModule,
        ProcessModule,
        InventoryModule,
        ErpModule,
        TimeTrackingModule,
        CrmModule,
        HardwareModule,
        WorkflowModule,
        ControllingModule,
        AdminModule,
        CustomerPortalModule,
        WhistleblowerModule
    ],
    providers: [    TutorialService,
                    SettingsService,
                    {provide: LOCALE_ID, useValue: 'de'}, {
                        provide: APP_INITIALIZER,
                        useFactory: settingsInit,
                        multi: true,
                        deps: [SettingsService]
                    }],
    bootstrap: [AppComponent]
})
export class AppModule { }
