import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../services/settings.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ModalService } from 'src/app/_modal';
import { Router} from '@angular/router';
import {getLocaleFirstDayOfWeek} from "@angular/common";

@Component({
  selector: 'app-top-bar-notification',
  templateUrl: './top-bar-notification.component.html',
  styleUrls: ['./top-bar-notification.component.css']
})
export class TopBarNotificationComponent implements OnInit {

  public overlayId: string;
  public OverlayActive = false;
  public showTeam = 0;
  public notification = {};
  public data = {
    name: '',
    status: '',
    message: '',
    newMail: '0',
    unassignedWorkDocument: '0',
    unfinishedWorkDocument: '0',
    notApprovedDocuments: '0',
    newNotification: '0',
    sessionTerminated: '0',
    messages: null,
    popupMessages: null,
    isNotAssignedTeamMail: '0',
    documentPortalActive: '0',
    maintenance: '0',
    isNotAssignedTeamMailTimeout: '0'
  };
  public markData = {};
  public isNotAssignedTeamMailTimeout;
  public processModuleActive = 0;
  public messageBoxText: string;
  calenderActionOption = -1;
  public notificationActive: boolean = true;
  private intervalHandler: number;
  private notificationAktiveIntervalHandler: number;
  private notificationAktiveTimestamp: any;
  private silentModeRuntime: number = 5400000; // Laufzeit des Ruhemodus in Sekunden

  constructor(public http: HttpClient, public settings: SettingsService, public message: ModalService, public router: Router) {
    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/right/Tickets/Modul', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              this.processModuleActive = data.message;
              if(this.processModuleActive == 1 && this.settings.data.hasTeamIcon) { this.showTeam = 1;}
            }
        ));
  }

  openInbox(): void {
    this.data.newMail = '0';
    this.router.navigate(['/process' , '6']);
  }

  ngOnInit() {
    const dateNow = new Date();
    this.overlayId = + dateNow + '_' + Math.floor((Math.random() * 9999) + 1000);
    this.settings.notificationManager = this;
    this.notificationAktiveTimestamp = this.settings.get('notificationAktiveTimestamp');
    if(this.notificationAktiveTimestamp == 0 || this.notificationAktiveTimestamp == false) {
      this.startDataReloadInterval();
    } else {
      this.startNotificationAktiveCheckInterval();
    }
  }

  private startNotificationAktiveCheckInterval() {
    this.notificationActive = false;
    this.clearNotificationIcons();
    if(this.intervalHandler) { clearInterval(this.intervalHandler); }
    this.notificationAktiveIntervalHandler = setInterval(() => {
      // hier prüfen ob Ruhemodus noch aktiv
      this.http.get<string>(this.settings.restBaseUrl + 'user/setting/' + 'notificationAktiveTimestamp',   this.settings.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            if(data=='0' || Math.floor(Date.now() / 1000) - Number(data) > this.silentModeRuntime) {
              // Settings aktualisieren
              const index = this.settings.user.findIndex((obj => obj.name === name));
              if (index === -1) { this.settings.user.push({ name,  data }); } else { this.settings.user[index].value = data; }
              this.notificationAktiveTimestamp = data;
              this.startDataReloadInterval()
            }
          }
      );

      if( this.notificationAktiveTimestamp == 0 || Math.floor(Date.now() / 1000) - this.notificationAktiveTimestamp > this.silentModeRuntime){
        this.startDataReloadInterval();
      }
    }, 10000);
  }


  private startDataReloadInterval() {
    this.notificationActive = true;
    if(this.notificationAktiveIntervalHandler) { clearInterval(this.notificationAktiveIntervalHandler); }
    this.updateData();
    this.intervalHandler = setInterval(() => {
      // Prüfen, ob in anderem Tab der Ruhemodus aktiviert wurde
      this.http.get<string>(this.settings.restBaseUrl + 'user/setting/' + 'notificationAktiveTimestamp',   this.settings.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            if(data!='0') {
              // Settings aktualisieren
              const index = this.settings.user.findIndex((obj => obj.name === name));
              if (index === -1) { this.settings.user.push({ name,  data }); } else { this.settings.user[index].value = data; }
              this.notificationAktiveTimestamp = data;
              this.startNotificationAktiveCheckInterval()
              return;
            }
            this.updateData();
          }
      );

    }, 10000);
  }

  private updateData() {
    this.updateNotificationData();
    this.updateNotification();
    this.updateMarkedData();
  }

  updateNotification()
  {
    this.http.post<{any}>(this.settings.restBaseUrl + 'menu/userNotification', {}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.notification = data;
        }
    );
  }

  updateMarkedData()
  {
    this.http.post<{any}>(this.settings.restBaseUrl + 'menu/marked', {}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.markData = data;
        }
    );
  }

  updateNotificationData() {
    if(this.notificationActive == false) { return; }
    this.http.post<{
          name: string,
          status: string,
          message: string,
          newMail: string,
          unassignedWorkDocument: string,
          unfinishedWorkDocument: string,
          notApprovedDocuments: string,
          newNotification: string,
          sessionTerminated: string,
          messages: object,
          popupMessages: object,
          isNotAssignedTeamMail: string,
          documentPortalActive: string,
          maintenance: string,
          isNotAssignedTeamMailTimeout: string
        }>(this.settings.restBaseUrl + 'menu/notification', {}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.data = data;
          if(this.data.maintenance === '1' ) {
            if(window.location.hostname.toLowerCase().includes("test")) {
              document.body.style.backgroundColor = '#ab1b31';
            } else {
              document.body.style.backgroundColor = '#ffc862';
            }
          } else {
            if(window.location.hostname.toLowerCase().includes("test")) {
              document.body.style.backgroundColor = '#25ab1b';
              } else {
                document.body.style.backgroundColor = '#497EDC';
              }
          }
          if(this.data.isNotAssignedTeamMailTimeout != '0' ) {
            if(this.isNotAssignedTeamMailTimeout != this.data.isNotAssignedTeamMailTimeout) {
              this.message.open('notificationCenterTimeoverMessage');
              this.isNotAssignedTeamMailTimeout = this.data.isNotAssignedTeamMailTimeout;
            }
          }
          if ( this.data.sessionTerminated === '1' ) {
            this.settings.sessionId = '';
            document.location.href = '../ui/index.html?session_id=#/login';
          }
          this.checkForPopupMessages();
        }
    );
  }

  checkForPopupMessages() {
    if ( this.data.messages === null && this.data.popupMessages === null ) { return; }
    let closePopup = true;
    this.data.messages.forEach((item) => {
      if (item.popup === '1') {
        closePopup = false;
        this.message.open('notificationCenterMessage');
        return;
      }
    });
    this.data.popupMessages.forEach((item) => {
      if (item.popup === '1' || item.popup === '3') {
        closePopup = false;
        this.message.open('notificationCenterMessage');
        return;
      }
    });
    if ( closePopup ) {
      this.message.close('notificationCenterMessage');
    }
  }

  activateOverlay(): void {
    this.OverlayActive = true;
    document.getElementById(this.overlayId).style.marginTop = '5px';
    document.getElementById(this.overlayId).style.marginLeft = '-69px';
    document.getElementById(this.overlayId).style.width = '600px';
    document.getElementById(this.overlayId).style.height = '300px';
    document.getElementById(this.overlayId).style.visibility = 'visible';
  }

  deactivateOverlay(): void {
    this.OverlayActive = false;
    setTimeout(() => {
      document.getElementById(this.overlayId).style.visibility = 'hidden';
    }, 100);
  }

  toggleOverlay() {
    if (this.OverlayActive === false) { this.activateOverlay(); } else { this.deactivateOverlay(); }
  }


  openManuallyAssign() {
    this.router.navigate(['/process-manually-assign']);
    this.deactivateOverlay();
  }

  openFinishWorkdocument() {
    this.router.navigate(['/process-finish-workdocument']);
    this.deactivateOverlay();
  }

  openApproveDocument() {
    // this.router.navigate(['/core-frame' , encodeURIComponent('../app/view.php' + '?session_id=' + this.settings.sessionId + '#/document_list')]);
    this.router.navigate(['/document/assign']);
    this.deactivateOverlay();
  }

  markedAction(item,action)
  {
    let goToId;
    let list;
    if(action===0) { action = 'edit'; goToId = item.id; list = item.list; }
              else { action = 'view'; goToId = item.tid; list = item.list; }
    this.http.get<{status, message}>(this.settings.restBaseUrl + 'process/active/' + item.tid, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              this.deactivateOverlay();
              if(item.id > 0) {
                this.router.navigate(['/process', goToId, action, list, data.message]);
              } else {
                this.router.navigate(['/process', goToId, action, list, data.message]);
              }
            }
        ));
  }

  notificationAction(item)
  {
              if(item.id > 0) {
                this.router.navigate(['/core-information', item.id]);
              } else {
                this.router.navigate(['/core-information', 0]);
              }

  }

  deleteMarked(item)
  {
    this.http.delete<{status, message}>(this.settings.restBaseUrl + 'menu/marked/' + item.id,  this.settings.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
          }
      );
      this.updateMarkedData();
  }

  openTeam() {
    this.deactivateOverlay();
    this.router.navigate(['/process' , '3']);
  }

  handleMessagePopupEvent(x)
  {
    let all = 0;

    for (let i = 0; i < this.data.popupMessages.length; i++) {
      if (this.data.popupMessages[i].id === x.id ) {
        this.data.popupMessages.splice(i, 1);
      }}

    let navigation
    if ( x.nextRemindInSeconds === '888') {
      x.nextRemindInSeconds = '900';
      all = 1;
      navigation = x.link.split(',');
    }

    this.message.close('notificationCenterMessage');

    this.http.put<{status, message}>(this.settings.restBaseUrl + 'information/remindTime/'+all+'/'+x.nextRemindInSeconds, JSON.stringify(x), this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
          if(all == 1){
              this.router.navigate(navigation);}
            }
        ));

  }

  handlePopupEvent(x) {
    let goToTid = null;
    let goToCalendar = false;
    if ( x.nextRemindInSeconds === '999') {
      // Zu Vorgang wechseln und in 5 Minuten erinnern
      x.nextRemindInSeconds = '300';
      goToTid = x.tid;
      console.log(goToTid);
    }
    if ( x.nextRemindInSeconds === '888') {
      // Zu Vorgang wechseln und in 5 Minuten erinnern
      x.nextRemindInSeconds = '300';
      goToCalendar = true;
    }
    // Element entfernen
    for (let i = 0; i < this.data.messages.length; i++) {
      if (this.data.messages[i].cid === x.cid ) {
        this.data.messages.splice(i, 1);
      }
    }
    // Wenn alle Elemente entfernt dann Fenster schließen
    if ( this.data.messages.length === 0 ) {
      this.message.close('notificationCenterMessage');
    }
    // Änderung an Server senden
    this.http.put<{status, message}>(this.settings.restBaseUrl + 'calendar/date/remindTime', {nextRemindInSeconds:x.nextRemindInSeconds, rid: x.rid }, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
            if(goToCalendar == true) {
              this.settings.calendar.activateOverlay();
              //this.router.navigate(['/core-frame', encodeURIComponent('../calendar/calendar.php' + '?session_id=' + this.settings.sessionId )]);
            }
            if ( goToTid !== null ) {
              this.message.close('notificationCenterMessage');
              this.settings.calendar.viewProcess(goToTid);
              //this.router.navigate(['/process' , goToTid, 'view']);
            }
          }
        ));

  }

  openDocumentPortal() {
    this.deactivateOverlay();
    this.router.navigate(['/document/portal']);
  }

  toggleNotification() {
    if(this.notificationActive){
      this.notificationActive = false;
      this.notificationAktiveTimestamp = Math.floor(Date.now() / 1000);
      this.settings.set("notificationAktiveTimestamp",this.notificationAktiveTimestamp,1);
      this.startNotificationAktiveCheckInterval();
    } else {
      this.settings.set("notificationAktiveTimestamp", 0, 1);
      this.startDataReloadInterval();
    }
  }

  clearNotificationIcons() {
    this.data.newMail = "0";
    this.data.isNotAssignedTeamMail = "0";
    this.data.newNotification = "0";
  }
}
