<!-- Nicht vergessen das neue UI element im UI Modul zu deklarieren und zu exportieren -->
<div style="display: inline-block; vertical-align: middle; margin-bottom: 1px;" id="{{overlayId}}UiTemplate" #overlayContainer >

    <table style="width:100%;" cellpadding="0" cellspacing="0" style="border: unset; border-collapse: collapse;">
        <tr>
            <td class="form">
                <input type="text"
                       id="{{overlayId}}UiTemplate"
                       name="{{overlayId}}UiTemplate"
                       [(ngModel)]="ngModel"
                       (blur)="blurEvent($event)"
                       (focus)="focusEvent($event)"
                       (click)="clickEvent($event)"
                       (keyup)="keyupEvent($event)"
                       [disabled]="disabled"
                       [placeholder]="placeholder"
                       [tabindex]="tabindex"
                       [ngClass]="{ 'error' : highlight }"
                       style="margin-top: -1px">
            </td>
            <td style="width:14px;">
                <button style="margin-top: 0px; margin-left:-5px; width:16px; min-width: 16px; max-width: 16px; height:24px; min-width: 14px !important; z-index: 20;border: 1px solid #ceced2;" ng-disabled="ngDisabled" tabindex="-1">
                    <img (click)="activateOverlay()" src="../design/icons/sort_arrow_down.png" style="width:20px; height: 20px; margin-left:-9px;">
                </button>
            </td>
        </tr>
    </table>

    <div id="{{overlayId}}" *ngIf="OverlayActive" [style.width.px]="overlayWidth" class="shadow form" style="height:fit-content; max-height: 200px; overflow: auto; color: #000000; position: absolute; z-index: 9999999; background-color: #ffffff; border: 1px solid #999999;" >
        <table style="width: 100%;" class="data_tabel pointer" >
            <tr *ngFor="let x of overlayOptions" ><td (click)="selectOverlayValue(x)" [ngClass]="{ 'error' : x.selected }">{{x.name}}</td></tr>
        </table>
    </div>

    <div (click)="deactivateOverlay()" [ngClass]="{'cpuiClosePanelOverlayOpen': OverlayActive, 'cpuiClosePanelOverlay':!OverlayActive}">
    </div>
</div>
