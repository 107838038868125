import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularSplitModule } from 'angular-split';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { UiModule } from '../ui/ui.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { PipesModule } from '../pipes/pipes.module';
import { ModalModule } from '../_modal';
import { WorkflowFormComponent } from './workflow-form/workflow-form.component';
import { WorkflowComponent } from './workflow/workflow.component';
import { WorkflowRunningComponent } from './workflow-running/workflow-running.component';

@NgModule({
  declarations: [
      WorkflowComponent, WorkflowFormComponent, WorkflowRunningComponent
  ],
  imports: [
    CommonModule, HttpClientModule, FormsModule, UiModule, EditorModule, BrowserAnimationsModule, ModalModule, PipesModule,
    AngularSplitModule, ContextMenuModule
  ],
  exports: [
  ]
})
export class WorkflowModule { }
