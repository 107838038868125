import { Component } from '@angular/core';

@Component({
  selector: 'app-erp-receipt',
  templateUrl: './erp-receipt.component.html',
  styleUrl: './erp-receipt.component.css'
})
export class ErpReceiptComponent {

}
